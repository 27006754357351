export function srAutocompleteEmptyHelper() {
  const searchBox = document.querySelector('.js-yext-query');
  if (!searchBox) return;
  if (searchBox.value === '') return;

  const srText = document.querySelector('.yxt-AutoComplete-resultsCount')
  if (srText == null) return;
  srText.innerText = '0 autocomplete options found';
}

export function srAutocompleteHelper(results, currentSelected) {
  var message = '';
  if (currentSelected.includes("-")) {
    const searchbar = document.querySelector('.js-yext-query');
    if (searchbar) {
      searchbar.setAttribute('aria-activedescendant', '');
      searchbar.setAttribute('aria-expanded', 'false');
    }

    if (results.length == 1) {
      message = `${results.length} autocomplete option found. `;
    } else {
      message = `${results.length} autocomplete options found. `;
    }

    message += "Keyboard users, use up and down arrows to review and enter to select. Touch device users, explore by touch or with swipe gestures."
  } else {
    const searchbar = document.querySelector('.js-yext-query');
    if (searchbar) {
      searchbar.setAttribute('aria-activedescendant', `yxt-AutoComplete-option-SearchBar.autocomplete-${currentSelected.charAt(0)}-${currentSelected.charAt(1)}`);
      searchbar.setAttribute('aria-expanded', 'false');
    }
  }

  const srText = document.querySelector('.yxt-AutoComplete-resultsCount')
  if (srText == null) return;
  srText.innerText = message;
  return;

}

export const customsearchbar = `
{{#if title}}
<h1 class="yxt-SearchBar-title">{{title}}</h1>
{{/if}}
<div class="yxt-SearchBar">
<div class="yxt-SearchBar-container">
  {{#if useForm}}
    <form class="yxt-SearchBar-form">
      <label class="sr-only" 
             for="{{inputIdName}}"
             id="{{inputLabelIdName}}">
        {{labelText}}
      </label>
      <input class="js-yext-query yxt-SearchBar-input"
        id="{{inputIdName}}"
        type="text"
        name="query"
        value="{{query}}"
        {{#if _config.placeholderText}}placeholder="{{_config.placeholderText}}"{{/if}}
        aria-autocomplete="both"
        aria-activedescendant=""
        aria-owns="yxt-Autocomplete-results"
        aria-haspopup="listbox"
        aria-describedby="yxt-SearchBar-initialInstr"
      >
      <span id="yxt-SearchBar-initialInstr" class="sr-only">When autocomplete results are available use up and down arrows to review and enter to select.  Touch device users, explore by touch or with swipe gestures.</span>
      <button type="button"
              class="js-yxt-SearchBar-clear yxt-SearchBar-clear yxt-SearchBar--hidden"
              data-eventtype="SEARCH_CLEAR_BUTTON"
              data-eventoptions="{{eventOptions}}"
              data-component="IconComponent"
              data-opts='{ "iconName": "close" }'
              data-prop="icon"
      >
        <span class="yxt-SearchBar-clearButtonText sr-only">
          {{clearText}}
        </span>
      </button>
      <button {{#if useForm}}type="submit"{{else}}type="button"{{/if}}
        class="js-yext-submit yxt-SearchBar-button">
        {{#if submitIcon}}
          <div class="yxt-SearchBar-buttonImage"
              data-component="IconComponent"
              data-opts='{ "iconName": "{{submitIcon}}" }'
          ></div>
        {{else if _config.customIconUrl}}
          <div class="yxt-SearchBar-buttonImage"
              data-component="IconComponent"
              data-opts='{ "iconUrl": "{{_config.customIconUrl}}" }'
          ></div>
        {{else}}
          <div class="yxt-SearchBar-AnimatedIcon yxt-SearchBar-AnimatedIcon--paused js-yxt-AnimatedForward
            {{#if autoFocus}} yxt-SearchBar-AnimatedIcon--inactive{{/if}}"
            data-component="IconComponent"
            data-opts="{{json forwardIconOpts}}">
          </div>
          <div class="yxt-SearchBar-AnimatedIcon yxt-SearchBar-AnimatedIcon--paused js-yxt-AnimatedReverse
            {{#unless autoFocus}} yxt-SearchBar-AnimatedIcon--inactive{{/unless}}"
            data-component="IconComponent"
            data-opts="{{json reverseIconOpts}}">
          </div>
        {{/if}}
        <span class="yxt-SearchBar-buttonText sr-only">
          {{submitText}}
        </span>
      </button>
    </form>
  {{else}}
    <div class="yxt-SearchBar-input-wrapper">
      <label class="sr-only" 
             for="{{inputIdName}}"
             id="{{inputLabelIdName}}"
      >
        {{labelText}}
      </label>
      <input class="js-yext-query yxt-SearchBar-input"
        id="{{inputIdName}}"
        type="text"
        name="query"
        value="{{query}}"
        {{#if _config.placeholderText}}placeholder="{{_config.placeholderText}}"{{/if}}
        aria-label="{{labelText}}"
        aria-autocomplete="list"
        aria-controls="{{autocompleteContainerIdName}}"
        aria-haspopup="listbox"
      >
      <button type="button"
              class="js-yxt-SearchBar-clear yxt-SearchBar-clear yxt-SearchBar--hidden"
              data-eventtype="SEARCH_CLEAR_BUTTON"
              data-eventoptions="{{eventOptions}}"
              data-component="IconComponent"
              data-opts='{ "iconName": "close" }'
              data-prop="icon"
      >
        <span class="yxt-SearchBar-clearButtonText sr-only">
          {{clearText}}
        </span>
      </button>
      <button {{#if useForm}}type="submit"{{else}}type="button"{{/if}}
        class="js-yext-submit yxt-SearchBar-button">
        {{#if submitIcon}}
          <div class="yxt-SearchBar-buttonImage"
              data-component="IconComponent"
              data-opts='{ "iconName": "{{submitIcon}}" }'
          ></div>
        {{else if _config.customIconUrl}}
          <div class="yxt-SearchBar-buttonImage"
              data-component="IconComponent"
              data-opts='{ "iconUrl": "{{_config.customIconUrl}}" }'
          ></div>
        {{else}}
          <div class="yxt-SearchBar-AnimatedIcon yxt-SearchBar-AnimatedIcon--paused js-yxt-AnimatedForward
            {{#if autoFocus}} yxt-SearchBar-AnimatedIcon--inactive{{/if}}"
            data-component="IconComponent"
            data-opts="{{json forwardIconOpts}}">
          </div>
          <div class="yxt-SearchBar-AnimatedIcon yxt-SearchBar-AnimatedIcon--paused js-yxt-AnimatedReverse
            {{#unless autoFocus}} yxt-SearchBar-AnimatedIcon--inactive{{/unless}}"
            data-component="IconComponent"
            data-opts="{{json reverseIconOpts}}">
          </div>
        {{/if}}
        <span class="yxt-SearchBar-buttonText sr-only">
          {{submitText}}
        </span>
      </button>
    </div>
  {{/if}}
  <div id="{{autocompleteContainerIdName}}" class="yxt-SearchBar-autocomplete"></div>
</div>
</div>`

export const customautocomplete = `<div class="yxt-AutoComplete-wrapper js-yxt-AutoComplete-wrapper">
  {{#if hasResults}}
    <div class="yxt-AutoComplete">
      {{assign 'currentSelected' sectionIndex resultIndex}}
      {{#if promptHeader}}
        <ul class="yxt-AutoComplete-results">
          <li class="yxt-AutoComplete-option yxt-AutoComplete-option--promptHeader">
            {{promptHeader}}
          </li>
        </ul>
      {{/if}}
      {{#each sections}}
        <span class="yxt-AutoComplete-resultsCount" aria-live="assertive">
          {{!-- custom autocomplete screen reader text--}}
          {{srAutocompleteHelper results ../currentSelected}}
        </span>
  
        <ul role="listbox"
            class="yxt-AutoComplete-results"

            id="yxt-Autocomplete-results"
        >
        {{#if label}}
            <li class="yxt-AutoComplete-resultHeader" 
            >{{label}}</li>
        {{/if}}
        {{#each results}}
          {{assign 'currentIndex' @../index @index}}
          <li class="js-yext-autocomplete-option yxt-AutoComplete-option yxt-AutoComplete-option--item{{#ifeq ../../currentSelected ../../currentIndex}} yxt-selected{{/ifeq}}"
              data-value="{{value}}"
              data-short="{{shortValue}}"
              data-filter='{{json filter}}'
              data-eventtype="AUTO_COMPLETE_SELECTION"
              data-eventoptions='{"suggestedSearchText": "{{value}}"}'
              role="option"
              aria-selected="{{#ifeq ../../currentSelected ../../currentIndex}}true{{else}}false{{/ifeq}}"
              id="yxt-AutoComplete-option-{{../../_config.name}}-{{@../index}}-{{@index}}"
          >
              {{highlightValue this true}}
          </li>
        {{/each}}
        </ul>
      {{/each}}
    </div>
  {{else}}
    {{!-- custom screen reader text when autocomplete has no results --}}
    {{!-- <span class="yxt-AutoComplete-resultsCount" aria-live="polite">  --}}
      {{srAutocompleteEmptyHelper}}
    {{!-- </span> --}}
  {{/if}}
  </div>`;