/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */

export function _getDocumentLocale() {
  return document.documentElement.lang.replace('_', '-');
}
export function _getProfileFieldAtKeyPath(profile, keypath) {
  const paths = keypath.split('.');

  if (!paths.length) {
    console.error('invalid key path', keypath);
    return null;
  }

  return paths.reduce((haystack, needleKey) => {
    if (!haystack) {
      console.log('haystack was null or undefined', haystack, needleKey, idx);
      return null;
    }
    const needle = haystack[needleKey];
    if (!needle) {
      console.log('could not find ' + needleKey, haystack);
      return null;
    }

    return needle;
  }, profile);
}

export function longDate(profile, keyPath) {
  const dateString = _getProfileFieldAtKeyPath(profile, keyPath);
  if (!dateString) {
    return null;
  }
  const parsedDateString = dateString + 'T00:00:00';

  const date = new Date(parsedDateString);
  const locale = _getDocumentLocale();

  return {
    day: date.toLocaleString(locale, {day: 'numeric'}),
    month: date.toLocaleString(locale, { month: 'long'}),
    year: date.toLocaleString(locale, {year: 'numeric'}),
  }
}